<template>
  <div class="aboutx">
    <!-- 文字 -->
    <div class="text w clearfix"
         v-html="content">

    </div>
  </div>
</template>

<script>
import { webInfo } from "@/api/public";

export default {
  data () {
    return {
      newsId: 0,
      content: '',
      title: '',
      media_logo: '',
      media_name: '',
      browse_num: 0,
      time: '',
      hd: ['tjhd', 'zdhd']
    }
  },
  watch: {
    $route: {
      handler () {
        this.info()
      },
      deep: true
    }
  },
  methods: {
    info () {
      let query = this.$route.query;
      // console.log('query', query)
      webInfo({
        'key': this.hd[(query.type - 1) || 0]
      }).then(res => {
        if (res.code == '200') {
          let data = res.data;
          this.content = data.value;
        } else {
          // alert(res.message | '')
        }
      })
    }
  },
  created () {
    this.info()
  },
};
</script>

<style  scoped lang="scss">
.clearfix {
  padding: 50px;
  line-height: 30px;
}
.title {
  height: 70px;
  // width: 1200px;
  // background: burlywood;
  margin-top: 60px;
  position: relative;

  .p {
    margin-left: 20px;
  }
  img {
    position: absolute;
  }

  h5 {
    text-align: center;
    color: #565656;
    font-size: 24px;
    box-sizing: border-box;
    margin-bottom: 28px;
  }

  i {
    margin-left: 390px;
    color: #999999;
  }

  span {
    color: #999999;
    margin-left: 40px;
  }

  p {
    display: inline;
    color: #999999;
    margin-left: 40px;
  }
}
.text {
  margin-top: 40px;
  // background: brown;
  // height: 400px;
  box-sizing: border-box;

  p {
    line-height: 24px;
    margin-bottom: 12px;
    color: #333333;
  }
  img {
    margin-top: 20px;
  }
}
.wrap {
  margin-bottom: 80px;
  margin-top: 55px;

  h5 {
    text-align: center;
    font-weight: 400;
    color: #606060;
    line-height: 28px;
    font-size: 20px;
  }
  p {
    line-height: 24px;
    margin-bottom: 12px;
    color: #333333;
    line-height: 24px;
    margin-bottom: 12px;
    font-weight: 400;
  }

  img {
    margin-top: 35px;
  }
}
</style>